// src/pages/JoinPage.js
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function JoinPage() {
   const [email, setEmail] = useState('');
   const [message, setMessage] = useState('');

   const handleSubmit = async (e) => {
      e.preventDefault();
      console.log('Email submitted:', email);
      setMessage('Thank you for joining us!');
      setEmail('');

      // Send email to the server for subscribing
      try {
        const response = await fetch('/api/subscribe',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const result = await response.json();
        console.log('Server response:', result);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else {
          setMessage('Thank you for joining us!');
        }

      } catch (error) {
        console.error('Error subscribing:', error);
        setMessage('An error occurred. Please try again.');
      }

      setEmail('');
    };
  

  return (
    <>
    <Header />
    <div className="container pt-80 mx-auto py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Join Our Newsletter</h1>
      <p className="text-center text-gray-700">Subscribe to our newsletter for updates and information on the progress of our shop!</p>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email address'
          required
          className='w-full p-2 border border-gray-300 rounded mt-4'
        />
        <button type='submit' className='w-full bg-blue-600 text-white p-2 rounded mt-4'>
          Subscribe
          </button>
      </form>
      {message && <p className='text-center mt-4'>{message}</p>}
    </div>
    <Footer />
    </>
  );
}

export default JoinPage;
