// Primary role of ProductList component is to render ProductCard Components 
// As Long as its correctly passing "product" prop to the ProductCard.
// Not Much Need to Change this File. 

import React from 'react';
import ProductCard from './ProductCard';

function ProductList({ products }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      {products.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

export default ProductList;
