import React from 'react';
import HeroImage from '../assets/images/hero.jpg'; // Adjust the path if needed
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <section
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: `url(${HeroImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-75"></div> {/* Optional overlay */}
      
      <div className="relative z-10 container mx-auto h-full flex items-center justify-center text-center">
        
        <div className="text-white">
          <h1 className="text-5xl font-bodoni font-bold mb-4">Welcome to Our Store!</h1>
          <p className="text-xl font-bodoni mb-8">Discover our exclusive collection</p>
        <Link to="/collection" className="bg-blue-600 text-white py-2 px-4 rounded">Shop Now</Link>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
