// src/components/Cart.js
import React, { useContext } from 'react';
import CartContext from '../contexts/CartContext';
import { Link } from 'react-router-dom';

// Cart component
const Cart = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);

  // Return cart items
  return (
    <div className="cart-dropdown absolute right-0 top-16 w-80 bg-white shadow-lg p-4 max-h-[70vh] overflow-auto">
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          {cartItems.map((item) => (
            <div key={item.id} className="flex items-center justify-between mb-4">
              <img src={item.image1} alt={item.name} className="w-16 h-16 object-cover" />
              <div className="ml-4">
                <p className="font-bold text-sm">{item.name}</p>
                <p className="text-sm">Price: ${item.price}</p>
                <p className="text-sm">Quantity: 1</p> {/* Assuming quantity is 1 for simplicity */}
                <button
                  onClick={() => removeFromCart(item.id)}
                  className="text-blue-600 text-xs"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="border-t pt-4 mt-4">
            <p className="font-bold">Subtotal: ${cartItems.reduce((sum, item) => sum + item.price, 0)}</p>
            <Link to="/checkout" className="bg-black text-white py-2 px-4 mt-4 block text-center">
              View Bag & Checkout
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
