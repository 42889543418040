import React, { useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import CartContext from '../contexts/CartContext';

const CheckoutPage = () => {
  const stripe = useStripe();
  const { cartItems } = useContext(CartContext);

  const handleCheckout = async () => {
    console.log("Cart Items:", cartItems);

    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ items: cartItems}),
    
    
      });
  
      const { id } = await response.json();
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
  
      if (error) {
        console.error('Stripe checkout error:', error);
      }
    } catch (error) {
      console.error('Error in handleCheckout:', error);
    }
  };
  
  return (
    <div className="container pt-80 mx-auto py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Checkout</h1>
      <div className="bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl mb-4">Order Summary</h2>
        <ul>
          {cartItems.map((item) => (
            <li key={item.id} className="flex justify-between mb-4">
              <div>{item.name}</div>
              <div>${item.price}</div>
            </li>
          ))}
        </ul>
        <div className="text-right text-xl font-bold mt-4">
          Total: ${cartItems.reduce((sum, item) => sum + item.price, 0)}
        </div>
        <button
            onClick={handleCheckout}
            className="bg-blue-600 text-white py-2 px-4 rounded mt-4"
        >
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default CheckoutPage;
