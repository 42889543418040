import React, { useContext } from 'react';
import CartContext from '../contexts/CartContext';

const AddToCartButton = ({ product }) => {
  const { addToCart } = useContext(CartContext);

  return (
    <button onClick={() => addToCart(product)} className="bg-blue-600 text-white py-2 px-4 rounded">
      Add to Cart
    </button>
  );
};

export default AddToCartButton;
