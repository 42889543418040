import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../assets/images/Logo.png';
import CartContext from '../contexts/CartContext';
import Cart from './Cart';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const { cartItems } = useContext(CartContext);
  const [cartVisible, setCartVisible] = useState(false);

// Show cart on mouse enter
let timer;
const handleMouseEnter = () => {
  clearTimeout(timer);
  setCartVisible(true);
};
// Hide cart on mouse leave
const handleMouseLeave = () => {
  timer = setTimeout(() => {
    setCartVisible(false);
  }, 500); // increase to add delay
};

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
// Listen for scroll events
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <header className="fixed top-0 w-full z-50 bg-white shadow">
      <div className="bg-gray-100 py-1">
        <div className="container mx-auto flex justify-end items-center text-sm">
          <div className="flex space-x-3">
            <Link to="/feedback" className="cursor-pointer">Feedback</Link>
            <Link to="/search" className="cursor-pointer">Search</Link>
            <Link to="/join" className="cursor-pointer">Join</Link>
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Cart ({cartItems.length})
              {cartVisible && <Cart />}
            </div>
          </div>
        </div>
      </div>
      <div className={`transition-all duration-300 ${isScrolled ? 'pt-0' : 'pt-4'}`}>
        <div className={`container mx-auto flex flex-col items-center ${isScrolled ? 'pb-2' : 'pb-6'}`}>
          <Link to="/">
            <img src={LogoImage} alt="Logo" className={`transition-all duration-300 ${isScrolled ? 'h-0' : 'h-12'}`} />
          </Link>
          <Link to="/" className={`text-2xl font-bold ${isScrolled ? '' : 'mt-3'}`}>
            Like Father Like Son
          </Link>
          <nav className="flex space-x-4 mt-4">
            <Link to="/" className="mx-2">Home</Link>
            <Link to="/shop" className="mx-2">Shop</Link>
            <Link to="/collection" className="mx-2">Collections</Link>
            <Link to="/about" className="mx-2">About</Link>
            <Link to="/contact" className="mx-2">Contact</Link>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
