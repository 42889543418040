import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import ProductList from '../components/ProductList';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function HomePage() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://likefatherslikeson.com/api/products');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Get the top 3 products..
  const topProducts = products.slice(0, 3);

  return (
    <>
      <Header />
      <section className=" pt-60 container mb-4">
        <h2 className="font-bodoni font-bold text-2xl font-semibold mb-4">
          About Us
        </h2>
        <p className="pl-10 text-gray-700 font-bodoni">
         Join our Newsletter to be notified of the store launch and additional promotions, products, and collections. <br/>
        <Link to="/join" className="cursor-pointer text-green-700">Link to Newsletter</Link>
        <br/><br/></p>
        <p className="pl-10 text-gray-700 font-bodoni">
          Thank you for taking the time to visit our website. Coming soon in 2025.<br/> In the meanwhile, please feel free to leave feedback via link below, and subscribe to our newsletter. 
          <br/><br/>
          For full stack development inquires email: <a className='text-bold' href='mailto:Devbelloper@outlook.com' >Devbelloper@outlook.com</a> 
          <br/>
          <Link to="/feedback" className="cursor-pointer text-green-700">Link to Feedback</Link>
          
        </p>
      </section>
      <HeroSection />
      <div className="pt-70 container mx-auto p-4">
        <h2 className="text-2xl font-bodoni font-bold mb-4">Top three products.</h2>
        <ProductList products={topProducts} />
      </div>
      < Footer />
    </>
  );
}

export default HomePage;
