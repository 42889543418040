// src/pages/ContactPage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function ContactPage() {

   
  return (
    <>
      <Header />
      <div className="pt-60 container mx-auto p-4">
      <h1 class="text-4xl font-semibold mb-6">Contact Us <br/> </h1>
      <p className="pl-10 text-gray-700 font-bodoni">
         Join our Newsletter to be notified of the store launch and additional promotions, products, and collections. <br/>
        <Link to="/join" className="cursor-pointer text-green-700">Link to Newsletter</Link>
        </p>
        <p className="pl-10 text-gray-700 font-bodoni">
          <br/>
          For full stack development inquires email: <a className='text-bold' href='mailto:Devbelloper@outlook.com' >Devbelloper@outlook.com</a> 
          <br/>
          </p>
        </div>
      <Footer />
    </>
  );
}

export default ContactPage;
