// Responsible for fetching products and managing the list of products.

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductList from '../components/ProductList';

function ShopPage() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState('');
  const [filter, setFilter] = useState('');
  const [error, setError] = useState(null); // Added error state

  useEffect(() => {
    const fetchProducts = async () => {
      try {
          const response = await fetch('https://likefatherslikeson.com/api/products');
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setProducts(data);
          setFilteredProducts(data); // Initially, no filter is applied
      } catch (error) {
          console.error('Error fetching products:', error);
          setError('Failed to load products. Please try again later.');
      }
  };
  

    fetchProducts(); // Call the function to fetch products
  }, []);

  // Handle sorting
  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortOrder(value);

    let sortedProducts = [...filteredProducts];
    if (value === 'price-asc') {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (value === 'price-desc') {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else if (value === 'name-asc') {
      sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
    } else if (value === 'name-desc') {
      sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
    }

    setFilteredProducts(sortedProducts);
  };

  // Handle filtering
  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setFilter(value);

    const filtered = products.filter(product => 
      product.name.toLowerCase().includes(value)
    );
    setFilteredProducts(filtered);
  };

  return (
    <div>
      <Header />
      <main className="pt-80 container mx-auto p-4">
        <h1 className="text-4xl font-bold mb-8">Shop Our Products</h1>
  
        {error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                placeholder="Search products..."
                className="border p-2 rounded"
                value={filter}
                onChange={handleFilterChange}
              />
              <select className="border p-2 rounded" value={sortOrder} onChange={handleSortChange}>
                <option value="">Sort By</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
                <option value="name-asc">Name: A-Z</option>
                <option value="name-desc">Name: Z-A</option>
              </select>
            </div>
  
            {filteredProducts.length > 0 ? (
              <ProductList products={filteredProducts} />
            ) : (
              <div className="text-center text-gray-500">No products found.</div>
            )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}
export default ShopPage;
