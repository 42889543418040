// src/pages/SearchPage.js
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function SearchPage({ products }) {
  const [query, setQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);
    
    if (value.length > 0) {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(value) || 
        product.description.toLowerCase().includes(value)
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  return (
    <>
    <Header />
    <div className="container pt-80 mx-auto py-8">
      < h1 className="text-4xl font-bold mb-8 text-center">Search Our Products</h1>
      <div className="mb-8">
        <input 
          type="text" 
          value={query}
          onChange={handleSearch}
          placeholder="Search for products..." 
          className="w-full p-4 text-lg border border-gray-300 rounded-md"
        />
      </div>

      {filteredProducts.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProducts.map(product => (
            <div key={product.id} className="border rounded-lg overflow-hidden shadow-lg">
              <img src={product.image1 || 'default-image-url'} alt={product.name} className="w-full h-64 object-cover" />
              <div className="p-4">
                <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
                <p className="text-gray-700 mb-4">${product.price}</p>
                <p className="text-gray-700 mb-4">{product.description}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-700">No products found.</p>
      )}
    </div>
    <Footer />
    </>
  );
}

export default SearchPage;
