import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import collection1img from '../assets/images/collection1.jpg';
import collection2img from '../assets/images/collection2.jpg';
import collection3img from '../assets/images/collection3.jpg';
import collection4img from '../assets/images/collection4.jpg';
import { Link } from 'react-router-dom';


function CollectionPage() {
  const collections = [
    {
      id: 1,
      name: "Like Father, Like Son",
      description: "The collection that inspired it all. Our exclusive selection of high-quality, limited-edition styles is perfect for fathers and sons who value timeless fashion and strong bonds.",
      imageUrl: collection1img, // Adjust the image path as necessary
    },
    {
      id: 2,
      name: "Husband & Wife Ensemble",
      description: "The collection for couples who appreciate elegance before embarking on the journey into parenthood. Discover our curated range of refined, matching wares designed to celebrate love and sophistication.",
      imageUrl: collection4img, // Adjust the image path as necessary
    },
    {
      id: 3,
      name: "Father & Daughter Matching Outfits",
      description: "An exquisite collection for fathers and daughters who cherish their unique connection. Explore our stylish, high-end coordinated looks that reflect the perfect blend of strength and sweetness.",
      imageUrl: collection2img, // Adjust the image path as necessary
    },
    {
      id: 4,
      name: "Family Harmonized ",
      description: "More than just matching attire—our collection offers coordinated looks for the entire family, including finely tailored pieces that go beyond the ordinary. Perfect for those who value quality and exclusivity in every detail.",
      imageUrl: collection3img, // Adjust the image path as necessary
    },
    {
      id: 5,
      name: "Seasons of Love",
      description: "Celebrate the changing seasons with our exclusive collection of matching outfits for the whole family. From cozy winter wear to breezy summer styles, we have the perfect looks for every time of year.",
      imageUrl: collection3img, // Adjust the image path as necessary
    },
  ];

  return (
    <div>
      <Header />

      <main className="pt-80 container mx-auto py-8">
        <h1 className="text-4xl font-bodoni font-bold mb-8 text-center">Our Collections</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {collections.map((collection) => (
            <div key={collection.id} className="border rounded-lg overflow-hidden shadow-lg">
              <img src={collection.imageUrl} alt={collection.name} className="w-full h-64 object-contain" />
              <div className="p-4">
                <h2 className="text-2xl font-bold mb-2">{collection.name}</h2>
                <p className="text-gray-700 mb-4">{collection.description}</p>
                <Link to={`/collection/${collection.id}`} className="bg-blue-600 text-white py-2 px-4 rounded">Shop Now</Link>
              </div>
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default CollectionPage;
