import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AddToCartButton from '../components/AddToCartButton';
import Header from '../components/Header';
import Footer from '../components/Footer';

function ProductPage({ products, onAddToCart }) {
  const { productId } = useParams();
  const location = useLocation();
  const productFromState = location.state?.product;

  // Initialize state for selected image
  const [selectedImage, setSelectedImage] = useState(null);

  // Use the product from state or find it in the list
  const product = productFromState || products.find(p => p.id === productId);

  useEffect(() => {
    //console.log("Products passed to ProductPage:", products);
    //console.log("Product ID from URL:", productId);
    //console.log("Product Images:", product?.img1, product?.img2, product?.img3);

    if (product) {
      // Set the initial selected image after the product is found
      setSelectedImage(product.image1 || (product.images && product.images[0]) || 'default-image-url');
    }
  }, [products, productId, product]);

  // Handle case where products is undefined or empty
  if (!products || products.length === 0) {
    //console.log("Products are not available yet.");
    return <div>Loading...</div>;
  }

  // Handle case where the product isn't found
  if (!product) {
    //console.log("Product not found for ID:", productId);
    return <div>Product not found</div>;
  }

  // Get the list of images from the product object
  const productImages = [product.image1, product.image2, product.image3, ...(product.images || [])].filter(Boolean);
  const hasImages = productImages.length > 0;

  return (
    <>
    <Header />
    <div className="pt-80 container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        
        {/* Product Images */}
        <div>
          {hasImages ? (
            <>
              <img src={selectedImage} alt={product.name} className="w-full h-auto object-contain mb-4 transition-transform duration-300 ease-in-out transform hover:scale-125" />
              <div className="flex space-x-2">
                {productImages.map((image, index) => (
                  <img 
                    key={index} 
                    src={image} 
                    alt={product.name} 
                    className="w-20 h-20 object-cover cursor-pointer" 
                    onClick={() => setSelectedImage(image)} 
                  />
                ))}
              </div>
            </>
          ) : (
            <p>No images available for this product.</p>
          )}
        </div>

        {/* Product Details */}
        <div>
          <h1 className="text-4xl font-bold mb-2">{product.name}</h1>
          <p className="text-2xl text-gray-700 mb-4">${product.price}</p>
          <p className="mb-6">{product.description}</p>
          
          {/* Quantity Selector */}
          <div className="flex items-center mb-4">
            <label htmlFor="quantity" className="mr-4">Quantity</label>
            <input 
              type="number" 
              id="quantity" 
              name="quantity" 
              defaultValue={1} 
              min={1} 
              className="border p-2 w-16 text-center"
            />
          </div>

          {/* Add to Cart Button */}
          <AddToCartButton product={product} onAddToCart={onAddToCart} />
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default ProductPage;
