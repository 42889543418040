import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <p>&copy; {new Date().getFullYear()} Like Father Like Son. All rights reserved.</p>
        <p className="mt-2">
          Follow us on 
          <a href="https://x.com/LFatherLSon" target='_blank' rel="noreferrer" className="text-blue-500 hover:underline mx-1">X</a> | 
          <a href="https://facebook.com" target='_blank' rel="noreferrer" className="text-blue-500 hover:underline mx-1">Facebook</a> | 
          <a href="https://www.instagram.com/lfatherlson/" target='_blank' rel="noreferrer" className="text-blue-500 hover:underline mx-1">Instagram</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
