// src/pages/AboutPage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Logo from '../assets/images/Logo.png';


function AboutPage() {
  return (
    <>
      <Header />
    <div className="pt-60 container mx-auto p-4">
    <img src={Logo} alt="Like Father Like Son" className="w-32 h-32 mx-auto mb-8" />    
    <h1 className="text-4xl font-bodoni font-bold mb-8 text-center">About Us</h1>
    <p class="text-lg text-gray-700 mb-4">
      At Like Father Like Son, we're all about bringing families closer through
      style. As a father, I often found it frustrating when I couldn't find
      clothes that matched with my kids—whether it was the fabric, the cut, or
      the overall style. This inspired us to create a brand that fills that gap
    </p>
    <p class="text-lg text-gray-700 mb-4">
      We offer beautifully crafted, matching outfits for every member of the
      family. Whether you're looking to coordinate with your spouse, daughter,
      or son, you've come to the right place. Our collections are designed with
      both style and quality in mind, making it easier than ever to create those
      picture-perfect moments together.
    </p>
    <p class="text-lg text-gray-700">
      Join us in celebrating family unity with fashion that’s made for everyone.
    </p>
    </div>
    <Footer />
    </>
  );
}

export default AboutPage;
