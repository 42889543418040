import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function FeedbackPage() {
  const [formData, setFormData] = useState({
    navigationRating: 3,
    foundInformation: '',
    whyNotFound: '',
    loadingSpeedRating: 3,
    confusingElements: '',
    websiteVibe: '',
    designAlignment: '',
    whyDesignNotAligned: '',
    colorSchemeDescription: '',
    colorSchemeEnhancement: '',
    colorSchemeChanges: '',
    personalRecommendations: '',
    contactInfo: ''
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data Submitted:', formData);

    // Send form data to the server
    try {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Server response:', result);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        setMessage('Thank you for your feedback!');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setMessage('An error occurred. Please try again.');
    }

    // Clear the form
    setFormData({
      navigationRating: 3,
      foundInformation: '',
      whyNotFound: '',
      loadingSpeedRating: 3,
      confusingElements: '',
      websiteVibe: '',
      designAlignment: '',
      whyDesignNotAligned: '',
      colorSchemeDescription: '',
      colorSchemeEnhancement: '',
      colorSchemeChanges: '',
      personalRecommendations: '',
      contactInfo: ''
    });
  };

  return (
    <>
      <Header />
      <div className="container pt-80 mx-auto py-8">
        <h1 className="text-4xl font-bold mb-8 text-center">We value your feedback!</h1>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          
          {/* Navigation Rating */}
          <label>
            How intuitive did you find the navigation on our website? (1-5)
            <input 
              type="number" 
              name="navigationRating" 
              value={formData.navigationRating} 
              onChange={handleChange} 
              min="1" 
              max="5"
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            />
          </label>

          {/* Found Information */}
          <label>
            Were you able to easily find the information you were looking for?
            <select 
              name="foundInformation" 
              value={formData.foundInformation} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          {/* Why Not Found (Only show if "No") */}
          {formData.foundInformation === 'No' && (
            <label>
              Why not?
              <textarea 
                name="whyNotFound" 
                value={formData.whyNotFound} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              />
            </label>
          )}

          {/* Loading Speed Rating */}
          <label>
            How would you rate the loading speed of the website and components? (1-5)
            <input 
              type="number" 
              name="loadingSpeedRating" 
              value={formData.loadingSpeedRating} 
              onChange={handleChange} 
              min="1" 
              max="5"
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            />
          </label>

          {/* Confusing Elements */}
          <label>
            Is there anything about the website's layout or functionality that you found confusing or difficult to use?
            <textarea 
              name="confusingElements" 
              value={formData.confusingElements} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
            />
          </label>

          {/* Website Vibe */}
          <label>
            What kind of vibe or atmosphere did you feel while browsing our website?
            <input 
              type="text" 
              name="websiteVibe" 
              value={formData.websiteVibe} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            />
          </label>

          {/* Design Alignment */}
          <label>
            Did the website's design and content align with the vibe you expected from our brand?
            <select 
              name="designAlignment" 
              value={formData.designAlignment} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </label>

          {/* Why Design Not Aligned (Only show if "No") */}
          {formData.designAlignment === 'No' && (
            <label>
              Why not?
              <textarea 
                name="whyDesignNotAligned" 
                value={formData.whyDesignNotAligned} 
                onChange={handleChange} 
                className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              />
            </label>
          )}

          {/* Color Scheme Description */}
          <label>
            How would you describe the color scheme of our website?
            <input 
              type="text" 
              name="colorSchemeDescription" 
              value={formData.colorSchemeDescription} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
              required
            />
          </label>

          {/* Color Scheme Enhancement */}
          <label>
            Did the color scheme enhance your browsing experience?
            <textarea 
              name="colorSchemeEnhancement" 
              value={formData.colorSchemeEnhancement} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
            />
          </label>

          {/* Color Scheme Changes */}
          <label>
            Are there any changes you would suggest for the color scheme?
            <textarea 
              name="colorSchemeChanges" 
              value={formData.colorSchemeChanges} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
            />
          </label>

          {/* Personal Recommendations */}
          <label>
            Personal Recommendations?
            <textarea 
              name="personalRecommendations" 
              value={formData.personalRecommendations} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
            />
          </label>

          {/* Contact Info */}
          <label>
            Contact Info (Optional):
            <input 
              type="text" 
              name="contactInfo" 
              value={formData.contactInfo} 
              onChange={handleChange} 
              className="w-full p-2 border border-gray-300 rounded mt-1 mb-4"
            />
          </label>

          <button type="submit" className="w-full bg-blue-600 text-white p-2 rounded mt-4">
            Submit Feedback
          </button>

          {message && <p className="text-center mt-4">{message}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
}

export default FeedbackPage;
