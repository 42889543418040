//Product Card
import React from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ product }) {
  // Use the first available image, or fallback to a default image
  const imageUrl = product.image1 || product.image2 || product.image3 || 'default-image-url';

  console.log('Product Data in ProductCard:', product);  // Log to see the received data


  return (
    <div className="border rounded-lg overflow-hidden shadow-lg">
      <img 
        src={imageUrl}
        alt={product.name} 
        className="w-full h-auto object-contain mb-4 transition-transform duration-300 ease-in-out transform hover:scale-125" />
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
        <p className="text-gray-700 mb-4">${product.price}</p>
        <Link
          to={`/product/${product.id}`}
          state={{ product }}  // Passing the product data as state
          className="bg-blue-600 text-white py-2 px-4 rounded"
        >
          View Product
        </Link>
      </div>
    </div>
  );
}

export default ProductCard;
