// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import CollectionPage from './pages/CollectionPage';
import CollectionDetailPage from './pages/CollectionDetailPage';
import ShopPage from './pages/ShopPage';
import JoinPage from './pages/JoinPage';
import CheckoutPage from './pages/CheckOutPage';
import SearchPage from './pages/SearchPage';
import Header from './components/Header';
import FeedbackPage from './pages/FeedbackPage';
import { CartProvider } from './contexts/CartContext';

// eslint-disable-next-line
const stripePromise = loadStripe('${process.env.REACT_APP_STRIPE_PUB_KEY}');

function App() {
  const [products, setProducts] = useState([]);

  // Fetch products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://likefatherslikeson.com/api/products');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <CartProvider>
      <div className="relative">
        <Router>
          <Header />
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/" element={<HomePage products={products} />} />
              <Route path="/shop" element={<ShopPage products={products} />} />
              <Route path="/product/:productId" element={<ProductPage products={products} />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/collection" element={<CollectionPage />} />
              <Route path="/collection/:collectionId" element={<CollectionDetailPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/join" element={<JoinPage />} />
              <Route path="/search" element={<SearchPage products={products} />} />
            </Routes>
          </Elements>
        </Router>
      </div>
    </CartProvider>
  );
}

export default App;