import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';


function CollectionDetailPage() {
  const { collectionId } = useParams();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`/api/products?collectionId=${collectionId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
      }
    };

    fetchProducts();
  }, [collectionId]);

  return (
    <div>
      <Header />
      <main className="pt-60 container mx-auto py-8">
        <h1 className="text-4xl font-bodoni font-bold mb-8 text-center">Collections..</h1>
        {error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {products.length > 0 ? (
              products.map((product) => (
                <div key={product.id} className="border rounded-lg overflow-hidden shadow-lg">
                  <img src={product.image1 || 'default-image-url'} alt={product.name} className="w-full h-64 object-cover" />
                  <div className="p-4">
                    <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
                    <p className="text-gray-700 mb-4">${product.price}</p>
                    <p className="mb-6">{product.description}</p>
                    <Link
                        to={`/product/${product.id}`}
                        state={{ product }}  // Passing the product data as state
                        className="bg-blue-600 text-white py-2 px-4 rounded"
                     >
                       View Product
                     </Link>

                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">No products found for this collection.</div>
            )}
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default CollectionDetailPage;
